.btn {
  &-primary {
    color: #fff !important;
  }
  &.arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: relative;
      transition: all 300ms ease-out;
      will-change: transform;
    }
    svg {
      position: absolute;
      width: 1.1em;
      right: 0px;
      right: 0rem;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 300ms ease-out;
      will-change: right, opacity;
    }

    &:hover {
      span {
        transform: translate3d(-1rem, 0, 0);
        svg {
          opacity: 1;
          right: -2rem;
        }
      }
    }
  }
}

.tab-box-btn {
  background: #ffffff;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: table;
  margin: 0 auto 30px;
  .wrapper {
    display: flex;
    padding: 25px 30px;
  }
  button {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #5f6062;
    opacity: 0.7;
    transition: 0.3s;
    background: transparent;
    box-shadow: none;
    padding: 0 30px;
    border: 0;
    &:hover,
    &.active {
      opacity: 1;
      color: $primary;
    }
  }
}

.tab-package-btn {
  background: #f6f6f7;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: table;
  margin: 0 0 40px;
  .wrapper {
    display: flex;
    padding: 25px 15px;
  }
  button {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #5f6062;
    opacity: 0.7;
    transition: 0.3s;
    background: transparent;
    box-shadow: none;
    padding: 0 14px;
    border: 0;
    &:hover,
    &.active {
      opacity: 1;
      color: $primary;
    }
  }
}

.link-page {
  display: flex;
  .item {
    font-size: 14px;
    line-height: 100%;
    padding: 0 17px;
    position: relative;
    &:first-child {
      &::before {
        display: none;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 3px;
      left: 0;
      top: 50%;
      background: $primary;
      border-radius: 55px;
      transform: translate(-50%, -50%);
    }
    a {
      color: rgba(255, 255, 255, 0.5);
    }
    span {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
