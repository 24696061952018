.book-slider-wrapper {
  overflow: hidden;
  margin-top: -61px;
}
.book-slider {
  width: 102vw;
  @media (max-width: $phone) {
    width: 100vw;
  }
  .owl-carousel {
    display: flex;
    flex-direction: column;
    .owl-stage-outer {
      order: 2;
    }
    .owl-nav {
      order: 1;
      text-align: right;
      padding-right: calc(3vw + 7.5%);
      margin-top: 0;
      margin-bottom: 91px;
    }
    .owl-dots {
      order: 3;
      text-align: right;
      padding-right: calc(3vw + 7.5%);
    }
  }
}
.book-SlideCard-wrapper {
  // padding: 0 30px;
  padding-left: 60px;
  @media (max-width: $xxxlDesktop) {
    padding-left: 45px;
  }
  @media (max-width: $phone) {
    padding: 0 15px;
  }
}
.book-SlideCard {
  width: 100%;
  padding-top: 151.905%;
  position: relative;

  border-radius: 25px;
  overflow: hidden;
  .wrapper {
    width: 100%;
    padding-top: 137.143%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    overflow: hidden;
    transition: 0.3s;
    background-color: #f3f3f3;
    &:hover {
      padding-top: inherit;
      .buy {
        height: 47px;
        margin-top: 21px;
      }
    }
    &:not(:hover) {
      .buy {
        height: 0;
        margin-top: 0;
      }
    }
    .render-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      object-fit: cover;
    }
    .dark-layer {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.56%, rgba(0, 0, 0, 0.75) 119.7%);
    }
    .content {
      z-index: 3;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 30px 25px;
      .title {
        font-family: "Trebuchet MS";
        font-weight: bold;
        font-size: 24px;
        line-height: 1.166665;
        color: #ffffff;
        @media (max-width: $xxxlDesktop) {
          font-size: 20px;
        }
        @media (max-width: $desktop) {
          font-size: 18px;
        }
      }
      .dec {
        font-family: "Trebuchet MS";
        font-size: 15px;
        line-height: 23px;
        color: rgba(#fff, 0.7);
        margin: 0;
        @media (max-width: $xxxlDesktop) {
          font-size: 14px;
        }
        @media (max-width: $desktop) {
          font-size: 13px;
        }
      }
      .buy {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s;
        .btn {
          width: 160px;
        }
        .price {
          font-family: "Trebuchet MS";
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          margin: 0;
        }
      }
    }
  }
}

.OffersYouMayLike-section {
  .container {
    max-width: 100%;
  }
  .sec-title {
    margin-bottom: 20px;
  }
}
.offer-card-col {
  margin-top: 30px;
}
.offer-card {
  background: #fff;
  color: #262626;
  box-shadow: 0px 0px 0px 1px #ccc;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  display: flex;
  height: 100%;
  cursor: pointer;
  transition: 0.3s;
  @media (max-width: $xxxlDesktop) {
    border-radius: 18px;
  }
  @media (max-width: $phone) {
    border-radius: 15px;
  }
  .logo-wrapper {
    flex: 0 0 160px;
    max-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 21px;
    position: relative;
    border-right: 2px dashed #ccc;
    @media (max-width: $xxxlDesktop) {
      flex: 0 0 120px;
      max-width: 120px;
      // max-height: 120px;
      padding: 18px 15px;
    }
    @media (max-width: $phone) {
      padding: 15px;
      flex: 0 0 90px;
      max-width: 90px;
      // max-height: 90px;
    }
    &::before,
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 100%;
      border-radius: 100%;
      background: #f9f9fa;
      margin-left: 1px;
    }
    &::before {
      top: 100%;
      transform: translate(-50%, -50%);
    }

    &::after {
      bottom: 100%;
      transform: translate(-50%, 50%);
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content {
    flex: 0 0 calc(100% - 160px);
    max-width: calc(100% - 160px);
    // height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 21px;
    // padding-left: 0;
    @media (max-width: $xxxlDesktop) {
      flex: 0 0 calc(100% - 120px);
      max-width: calc(100% - 120px);
      // height: 100px;
      padding: 18px 15px;
      // padding-left: 0;
    }
    @media (max-width: $phone) {
      flex: 0 0 calc(100% - 90px);
      max-width: calc(100% - 90px);
      // height: 90px;
      padding: 15px;
      // padding-left: 0;
    }
  }
  .brand {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 5px;
    @media (max-width: $xxxlDesktop) {
      font-size: 14px;
    }
  }
  .name {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 8px;
    @media (max-width: $xxxlDesktop) {
      font-size: 18px;
    }
  }
  .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .decription {
    font-size: 14px;
    margin-bottom: 8px;
    color: #c3c3c3;
  }
  .validTill {
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0;
    color: #c3c3c3;
    text-align: right;
    @media (max-width: $xxxlDesktop) {
      font-size: 13px;
    }
  }
}

.book-youre-experience-section {
  padding: 80px 0 150px;
  @media (max-width: $xxxlDesktop) {
    padding: 60px 0 112px;
  }
  @media (max-width: $xxlDesktop) {
    padding: 45px 0 84px;
  }
  .container {
    @media (max-width: $xlDesktop) {
      max-width: 100%;
    }
  }
  .sec-title {
    margin-bottom: 42px;
  }
}
.book-exp-card {
  margin-top: 30px;
}
.book-exp-card {
  width: 100%;
  padding-top: 39.509%;
  background-color: #ffffff;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  min-height: 300px;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(252.91deg, rgba(0, 0, 0, 0.1) -6.51%, rgba(0, 0, 0, 0.8) 107.51%);
    z-index: 1;
  }
  .content {
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-content {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        span {
          opacity: 0.7;
        }
      }
      .rating {
        width: 98px;
        height: 43px;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 10px;
          height: 20px;
        }
      }
    }
    .bottom-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.25;

        @media (max-width: $xxxlDesktop) {
          font-size: 27px;
        }
        @media (max-width: $xxlDesktop) {
          font-size: 20px;
          max-width: 80%;
        }
      }
      .price {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.166665;
        margin-bottom: 0;
        white-space: nowrap;
        @media (max-width: $xxxlDesktop) {
          font-size: 20px;
        }
        @media (max-width: $xxlDesktop) {
          font-size: 18px;
        }
      }
    }
  }
}
.uploadSection {
  position: relative;
  z-index: 2;
  margin-top: -50px;
  padding: 0 30px 80px;
  .UploadCard {
    margin: 0 auto;
    max-width: 800px;
    max-width: 1410px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;
    @media (max-width: $xxxlDesktop) {
      max-width: 1200px;
    }
    .UploadContent {
      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.25;
        margin-bottom: 20px;
        @media (max-width: $xxxlDesktop) {
          font-size: 26px;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.666666;
        color: #5f6062;
        @media (max-width: $xxxlDesktop) {
          font-size: 14px;
        }
      }
    }
    .UploadFile {
      .upload-select {
        position: relative;
        padding: 20px;
        cursor: pointer;
        width: 100%;
        height: 240px;
        display: block;
        background: #f9f9fa;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        box-shadow: 0px 7px 10px rgba(#000, 0.05%);
        transition: 0.3s;
        &:hover {
          box-shadow: 0px 7px 10px rgba(#000, 0.1%);
        }
        .unli {
          text-decoration: underline;
        }
        .form-control-file {
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          position: absolute;
          opacity: 0;
        }
      }
    }
  }
}
.voucherDetailView {
  position: relative;
  z-index: 2;
  margin-top: -50px;
  padding: 0 30px 80px;
  .voucherDetailView-card {
    margin: 0 auto;

    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;

    max-width: 1410px;

    @media (max-width: $xxxlDesktop) {
      max-width: 1200px;
    }

    .render-card {
      @media (max-width: $phone) {
        text-align: center;
      }
      .logo-wrapper {
        margin-bottom: 16px;
      }
      .name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .decription {
      }
      .validTill {
      }
    }
    .btn {
      border-radius: 8px;
      height: 42px;
    }
  }
}
.PackageCard {
  position: relative;
  z-index: 2;
  margin-top: -50px;
  padding: 0 50px 100px;
  .PackageCard-card {
    margin: 0 auto;
    max-width: 1410px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;
  }
  .package-content {
    .content {
      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.25;
        margin-bottom: 20px;
        @media (max-width: $xxxlDesktop) {
          font-size: 26px;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.666666;
        color: #5f6062;
        @media (max-width: $xxxlDesktop) {
          font-size: 14px;
        }
      }
      .duration {
        font-size: 15px;
        line-height: 19px;
        color: #262626;
        position: relative;
        padding-left: 30px;
        padding-top: 2px;
        img {
          top: 0;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
.PackageOptionCard {
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid rgba($primary-2, 0.5);
  .stepsTab {
    padding: 40px 40px 16px;
    border-bottom: 1px solid rgba($primary-2, 0.5);
    .wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 -5%;

      position: relative;
      .item {
        flex: 0 0 20%;
        max-width: 20%;
        text-align: center;
        position: relative;
        &:last-child {
          .btnWrapper {
            &::before {
              display: none;
            }
          }
        }
        .btnWrapper {
          position: relative;
          &.active,
          &.activeFull {
            &::before {
              content: "";
              position: absolute;
              height: 1px;
              width: 50%;
              top: 50%;
              left: 100%;
              background-color: red;
              transform: translate(-50%, -50%);
            }
          }
          &.active {
            &::before {
              background: $primary-2;
            }
          }
          &.activeFull {
            &::before {
              background: linear-gradient(to right, #ec6d59, #f6f6f7);
            }
          }
        }
        button {
          transition: 0.2s;
          width: 65px;
          height: 65px;
          border: 0;
          border-radius: 100%;
          margin-bottom: 6px;
          background: #f6f6f7;
          color: #9fa0a0;
          position: relative;
          &:hover,
          &.active {
            background: $primary-2;
            color: #fff;
          }
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: #262626;
          opacity: 0.7;
          margin-bottom: 0;
        }
      }
    }
  }
  .render-content {
    padding: 32px 40px 40px;
  }

  .usertab {
    margin-bottom: 40px;
    .row {
      margin-left: -11px;
      margin-right: -11px;
      & > div {
        padding-left: 11px;
        padding-right: 11px;
      }
    }
    .render-input {
      background: #f6f6f7;
      border-radius: 13px;
      padding: 16px 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .n-wrapper {
        svg {
          margin-right: 7px;
        }
      }
      .n-wrapper,
      .h-wrapper {
        display: flex;
        align-items: center;
      }
      .name {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
      }
      .value {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        min-width: 23px;
        text-align: center;
      }
      button {
        padding: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        color: #9fa0a0;
        &:hover {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
  .aed-qty-tab {
    table {
      width: 100%;
      th {
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: #5f6062;
        padding-bottom: 12px;
      }
      tbody {
        tr {
          td {
            padding-top: 10px;
            padding-bottom: 10px;
            .title {
              font-weight: bold;
              font-size: 15px;
              line-height: 19px;
              color: #262626;
            }
          }
        }
      }
    }
    .render-input {
      padding: 16px 25px 15px;
      border: 1px solid #b8b8b8;
      box-sizing: border-box;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #262626;
      }
      .value {
        min-width: 15px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #262626;
      }
      .handler {
        display: flex;
        align-items: center;
      }
      button {
        padding: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        color: #9fa0a0;
        &.add {
          color: $primary-2;
        }
        &:hover {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
}

.duration-tab {
  .theme-radio {
    margin-bottom: 33px;
  }
}
.car-tab {
  margin-bottom: 24px;
  table {
    width: 100%;
    th {
      padding-bottom: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 21px;
      color: #5f6062;
      &.title {
        width: 50%;
        color: $primary-2;
      }
    }
    tbody {
      td {
        padding-top: 21px;
        .name {
          max-width: 147px;
          display: inline-block;
        }
      }
    }
  }
}
.theme-radio {
  .form-check {
    padding-left: 0;
  }
  label {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #262626;
    cursor: pointer;
    position: relative;
    padding-left: 44px;
    span {
      border: 2px solid $primary-2;
      width: 24px;
      height: 24px;
      position: absolute;
      border-radius: 100%;
      left: 0;
      &::before {
        border-radius: 100%;
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        background-color: $primary-2;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.3s;
      }
    }
    .form-check-input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      &:checked {
        & + span {
          &::before {
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

.calander-tab {
  margin-bottom: 30px;
  .react-calendar {
    margin: auto;
    width: 100%;
  }
}
.theme-calander {
  .react-calendar {
    background: #f9f9fa;
    border-radius: 12px;
    overflow: hidden;
    border: 0;
    .react-calendar__month-view__weekdays {
      font-family: "Trebuchet MS";
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #262626;
      text-transform: initial;
      abbr {
        text-decoration: none;
      }
    }
    .react-calendar__tile--now {
      border: 1px solid $primary-2;
      border-radius: 40px;
      background: transparent;
    }
    .react-calendar__tile--active {
      background-color: $primary-2;
      border-radius: 40px;
    }
    .react-calendar__month-view__days {
      font-family: "Trebuchet MS";
      font-size: 14px;
      line-height: 24px;
      color: #262626;
    }
  }
}
