.search-destination {
  max-width: 584px;
  // @media (max-width: $xxxlDesktop) {
  //   max-width: 438px;
  // }
  // @media (max-width: $xxlDesktop) {
  //   max-width: 328px;
  // }
  .wrapper {
    background: #ffffff;
    padding: 11px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: flex;
    align-items: center;
    .imputs {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
    .des {
      display: flex;
      align-items: center;
    }
    .form-control {
      border: 0;
      box-shadow: none;
    }
    .btn {
      width: 115px;
      height: 47px;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.sign-up-section {
  margin-top: -200px;
  position: relative;
  z-index: 2;
  padding: 0 15px 150px;
}
.main-form-card {
  background: #fff;
  max-width: 689px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  margin: auto;
  padding: 50px 30px;
}

.main-form {
  max-width: 295px;
  margin: auto;
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #262626;
    margin-bottom: 30px;
  }
  .label {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #262626;
    margin-bottom: 8px;
  }
  .icon-input {
    position: relative;
    svg {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .react-tel-input .form-control {
    width: 100%;
  }
  .inp-wrapper {
    margin-bottom: 20px;
  }
  .form-control {
    background: #f6f6f7;
    border-radius: 13px;
    height: 55px;
    border: 0;
    padding-left: 59px;
    color: #5f6062;
    @media (min-width: $tablet) {
      font-size: 14px;
    }
  }
  .flag-dropdown {
    border: 0;
    left: 8px;
    background: transparent !important;
  }
  .react-tel-input .selected-flag:hover {
    background: transparent;
  }
  .btn {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border-radius: 13px;
  }
  .already {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    margin-top: 26px;
    margin-bottom: 0;
  }
}
