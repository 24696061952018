body {
  margin: 0;
  font-family: "Cera Pro";
  color: #262626;
  background: #f9f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container,
.container-xl {
  @media (min-width: 1200px) {
    max-width: 100%;
    padding-left: 7.1%;
    padding-right: 7.1%;
  }
}
.about-us-sec {
  background: {
    color: #f9f9fa;
    position: center bottom;
    size: cover;
  }
  position: relative;
  padding: 157px 0;
  @media (max-width: $xxxlDesktop) {
    padding: 117px 0;
  }
  @media (max-width: $xxlDesktop) {
    padding: 88px 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) -7.66%, rgba(0, 0, 0, 0.75) 98.82%);
    z-index: 1;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .sec-title {
    color: #ffffff;
    margin-bottom: 22px;
  }
  p {
    font-size: 17px;
    line-height: 1.47059;
    color: #ffffff;
    opacity: 0.7;
    margin-bottom: 50px;
    max-width: 23em;
  }
}

.BestDestination-section {
  padding: 90px 0;
  @media (max-width: $xxxlDesktop) {
    padding: 67px 0;
  }
  @media (max-width: $xxlDesktop) {
    padding: 50px 0;
  }
}

.OffersYouMayLike-section {
  padding: 80px 0;
  @media (max-width: $xxxlDesktop) {
    padding: 60px 0;
  }
  @media (max-width: $xxlDesktop) {
    padding: 45px 0;
  }
}

.best-traveling-section {
  padding: 90px 0 140px;
  @media (max-width: $xxxlDesktop) {
    padding: 67px 0 105px;
  }
  @media (max-width: $xxlDesktop) {
    padding: 50px 0 78px;
  }
  img {
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .sec-title-heading {
    margin-bottom: 10px;
  }
}
