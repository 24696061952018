#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: 0.3s;
  height: 142px;
  display: flex;
  align-items: center;
  @media (max-width: $xxxlDesktop) {
    height: 100px;
  }
  @media (max-width: $phone) {
    height: 80px;
  }
  &.isSticky {
    height: 100px;
    background-color: #262626;
    @media (max-width: $xxxlDesktop) {
      height: 80px;
    }
    @media (max-width: $phone) {
      height: 60px;
    }
  }

  .container,
  .container-xl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    img {
      @media (max-width: $xxxlDesktop) {
        height: 32px;
      }
      @media (max-width: $phone) {
        height: 25px;
      }
      @media (max-width: 375px) {
        height: 22px;
      }
    }
  }
  .menu-btn {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 16px;
  }
  .menu-bg {
    z-index: 99;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(#262626, 0.7);
    transform: translateX(-100%);
    // transition: 0.3s;
    &.active {
      transform: translateX(0);
    }
  }
  .menus {
    @media (max-width: $desktop) {
      transition: 0.3s;
      position: fixed;
      z-index: 998;
      height: 100vh;
      top: 0;
      left: 0;
      background: #262626;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 270px;
      transform: translateX(-100%);
      &.active {
        transform: translateX(0);
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      @media (max-width: $desktop) {
        flex-direction: column;
      }
      li {
        margin: 0 29px;
        list-style: none;
        @media (max-width: $xxxlDesktop) {
          margin: 0 21px;
        }
        @media (max-width: $desktop) {
          margin: 25px 16px;
        }
        a,
        button {
          font-weight: bold;
          font-size: 17px;
          line-height: 100%;
          position: relative;
          background: transparent;
          color: #fff;
          border: 0;
          padding: 0;
          box-shadow: none;
          text-decoration: none;
          &:hover,
          &.active {
            &::before {
              content: "";
              margin-top: 10px;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              width: 25px;
              height: 3px;
              background: $primary;
              border-radius: 55px;
            }
          }
        }
      }
    }
  }
  .links {
    .btn {
      margin-left: 28px;
      @media (max-width: $xxxlDesktop) {
        margin-left: 20px;
      }
      @media (max-width: $phone) {
        margin-left: 12px;
      }
    }
    a {
      color: #fff;
    }
    a,
    button {
      font-weight: bold;
      font-size: 17px;
      line-height: 100%;
      @media (max-width: $phone) {
        font-size: 14px;
      }
      @media (max-width: 375px) {
        font-size: 12px;
      }
    }
  }
}

#footer {
  background: #000;
  padding: 0 65px;
  @media (max-width: $tablet) {
    padding: 0 30px;
  }
  .wrapper {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: aoto 100%;
    display: flex;
    @media (max-width: $tablet) {
      flex-wrap: wrap;
      background-position: right bottom;
      background-size: auto 60%;
    }
    .info {
      width: 449px;
      background: #232323;
      padding: 50px;
      @media (max-width: $desktop) {
        width: 390px;
        padding: 35px;
      }
      @media (max-width: $tablet) {
        flex: 0 0 100%;
        max-width: 100%;

        width: 100%;
      }

      .logo {
        // opacity: 0.25;
        margin-bottom: 25px;
        max-width: 100%;
      }
      .info-text,
      .lable-text {
        font-size: 13px;
        line-height: 20px;
        color: #919195;
      }
      .info-text {
        margin-bottom: 50px;
      }

      .lable-text {
        margin-bottom: 20px;
      }
      .subsctibe {
        background: #1a1a1a;
        border-radius: 10px;
        display: flex;
        .form-control {
          border-radius: 10px;
          height: 50px;
          font-weight: bold;
          background: inherit;
          color: #919195;
          border: 0;
          line-height: 18px;
          box-shadow: none;
          @media (min-width: $tablet) {
            font-size: 14px;
          }
        }
        .btn {
          height: 50px;
          border-radius: 10px;
        }
      }
    }
    .f-menus {
      padding-left: 100px;
      padding-top: 50px;
      padding-bottom: 50px;
      @media (max-width: $xlDesktop) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 70px;
      }
      @media (max-width: $desktop) {
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 60px;
      }
      @media (max-width: $tablet) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 35px 30px;
      }

      h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 13px;
      }
      .menu-item {
        margin-bottom: 12px;
        a {
          display: inline-flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.5);
          svg {
            margin-left: 7px;
          }
        }
      }
      .b-links {
        margin-top: 35px;
        margin-bottom: 20px;
        a {
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: underline;
          color: #5f6062;
          margin-right: 30px;
        }
      }
      .copy-text {
        font-size: 14px;
        line-height: 16px;
        color: #5f6062;
        margin-bottom: 0;
      }
    }
  }
}
